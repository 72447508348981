import { useLazyQuery, useQuery } from '@apollo/client';
import { Box, Chip, Grid, Modal } from '@mui/material';
import dayjs from 'dayjs';
import AgGrid from 'components/MDGrid';
import { reportServiceClient } from 'graphql/client';
import { dashboardServiceClient } from 'graphql/client';
import React, { useContext } from 'react';
import { DateFiltersContext } from './index';;
import OrderDetail from './orderDetail';
import { GET_CUSTOMER_LOYALITY_DETAILS } from 'services/loyality-service';

function CustomerLoyalityDetails({ loyalityCustomer}) {
    const [open,setOpen]= React.useState(false)
    const [selectedRowData, setSelectedRowData] = React.useState(null);
    const columnDefs = [
        { field: "activityType", headerName: "Activity", width: 130 },
        {
            field: "orderId",
            headerName: "Order ID",
            width: 130,
            cellRenderer: params => {
                return (
                    <span
                        style={{ color: 'blue', cursor: 'pointer' }}
                        onClick={() => handleOrderIdClick(params.data)}
                        >
                        {params.value}
                    </span>
                );
            }
        },
            { field: "totalRewardsBurned", headerName: "Total Rewards Burned", width: 130 },
        { field: "totalRewardsEarned", headerName: "Total Rewards Earned", width: 130 },
        { field: "totalBalanceRewards", headerName: "Total Balance Reward", width: 130 },
        { field: "transactionId", headerName: "Transaction ID", width: 130 },
        {
            field: "orderDate",
            headerName: "Order Date",
            width: 130,
            valueFormatter: params => {
                const dateValue = params.value;
                return dateValue ? (dayjs(Number(dateValue)).isValid() ? dayjs(Number(dateValue)).format('DD-MM-YYYY') : dayjs(dateValue).isValid() ? dayjs(dateValue).format('DD-MM-YYYY') : "") : "";
            }        },
        {
            field: "rewardCreditDate",
            headerName: "Reward Credit Date",
            width: 130,
            valueFormatter: params => {
                const dateValue = params.value;
                return dateValue ? (dayjs(Number(dateValue)).isValid() ? dayjs(Number(dateValue)).format('DD-MM-YYYY') : dayjs(dateValue).isValid() ? dayjs(dateValue).format('DD-MM-YYYY') : "") : "";
            }        },
        {
            field: "rewardExpiryDate",
            headerName: "Reward Expiry Date",
            width: 130,
            valueFormatter: params => {
                const dateValue = params.value;
                return dateValue ? (dayjs(Number(dateValue)).isValid() ? dayjs(Number(dateValue)).format('DD-MM-YYYY') : dayjs(dateValue).isValid() ? dayjs(dateValue).format('DD-MM-YYYY') : "") : "";
            }        },
        { field: "orderTotal", headerName: "Order Total", width: 130 },
    ];
    const { startDate, endDate, setcustomersDetailsData } = useContext(DateFiltersContext);

    const {data,loading, error} = useQuery(GET_CUSTOMER_LOYALITY_DETAILS,
        {
        client: reportServiceClient,
        variables:{filter:{take:1000,skip:0,startDate:startDate,endDate:endDate,accountId:loyalityCustomer?.accountId}},
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            setcustomersDetailsData(data?.getLoyaltyCustomerDetails?.results)
        }
    },
    )
    const handleOrderIdClick = (rowData) => {
        setSelectedRowData(rowData);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Grid>
            <AgGrid
                gridProps={{
                    rowHeight: 60,
                    defaultColDef: {
                        editable: false,
                        filter: true,
                        flex: 1,
                        minWidth: 200,
                    },
                    pivotMode: false,
                    sideBar: {
                        toolPanels: ['columns', 'filters'],
                    },
                    enableRangeSelection: true,
                }}
                queryData={data?.getLoyaltyCustomerDetails?.results || []}
                columns={columnDefs}
            />
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflow: 'auto',
                    maxHeight: '90vh' 
                }}            >
                <OrderDetail selectedRowData={selectedRowData} handleClose={handleClose}/>
            </Modal>
        </Grid>
    );
}

export default CustomerLoyalityDetails;
