import { useQuery } from '@apollo/client';
import { Box, Typography, Grid, Chip, IconButton } from '@mui/material';
import { GridCloseIcon } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { reportServiceClient } from 'graphql/client';
import OrderDetailLandingPage from 'layouts/order/orderDetailsListing';
import React from 'react';
import { GET_EVENT_ORDER_DETAILS } from 'services/loyality-service';

function OrderDetail({ selectedRowData, handleClose }) {
    const { data, loading, error } = useQuery(GET_EVENT_ORDER_DETAILS, {
        client: reportServiceClient,
        variables: { orderId: selectedRowData?.orderId },
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });

    if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography>Error loading data</Typography>;

    const renderDetailRow = (label, value) => (
        <Grid container spacing={2} alignItems="center" mt={0.5}>
            <Grid item xs={6}>
                <Typography fontSize="12px" fontFamily="montserrat">
                    <strong>{label}</strong>
                </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'right' }}>
                <Typography fontSize="12px" fontFamily="montserrat">
                    {value}
                </Typography>
            </Grid>
        </Grid>
    );
    let isEvent = !selectedRowData?.activityType?.includes('Product')
    return (
        <Box sx={{ p: 4, margin: 'auto', marginTop: '10vh', marginBottom:"10vh", width:isEvent ? '45% ': '80%' , bgcolor: 'background.paper', borderRadius: 1 ,overflow:"auto"}}>
            <Grid container >
                <Grid item xs={6} mb={2}>
                    <Typography fontFamily="" fontSize='18px' id="modal-modal-title" >Event Order Details</Typography>
                </Grid>
                <Grid item xs={6} mb={2} sx={{display: 'flex',justifyContent:'end'}}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <GridCloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            {selectedRowData && data && (
                <>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <Typography fontSize='10px'>
                                <strong>Order ID :</strong> {selectedRowData?.orderId.slice(-8)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography fontSize='10px'>
                                <strong>Transaction ID :</strong> {selectedRowData?.transactionId}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography fontSize='10px'>
                                <strong>Order Date and Time :</strong> 
                                {selectedRowData?.orderDate ? (dayjs(Number(selectedRowData.orderDate)).isValid() ? dayjs(Number(selectedRowData.orderDate)).format('DD/MM/YYYY, h:mm A') : dayjs(selectedRowData.orderDate).isValid() ? dayjs(selectedRowData.orderDate).format('DD/MM/YYYY, h:mm A') : "Invalid Date") : ""}
                            </Typography>
                        </Grid>
                    </Grid>
                   { isEvent ? (<Box sx={{ mt: 2, p: 2, borderRadius: 1, border: '1px solid #ccc' }}>
                        {renderDetailRow("Event Name", data?.getLoyaltyEventOrderDetails?.eventName)}
                        {renderDetailRow("Event Type", data?.getLoyaltyEventOrderDetails?.eventType)}
                        {renderDetailRow("Ticket Type", data?.getLoyaltyEventOrderDetails?.ticketName)}
                        {renderDetailRow("Ticket Price (Rs.)", data?.getLoyaltyEventOrderDetails?.ticketPrice)}
                        {renderDetailRow("Total Tickets", data?.getLoyaltyEventOrderDetails?.totalTickets)}
                        {renderDetailRow("Total Price (Rs.)", data?.getLoyaltyEventOrderDetails?.totalPrice)}
                        {renderDetailRow("Rewards Earned", data?.getLoyaltyEventOrderDetails?.rewardsEarned)}
                        {renderDetailRow("Rewards Burned", data?.getLoyaltyEventOrderDetails?.rewardsBurned)}
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <Typography fontSize="12px" fontFamily="montserrat">
                                    <strong>Status</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: 'right' }}>
                                <Chip
                                    label={data?.getLoyaltyEventOrderDetails?.status}
                                    color={data?.getLoyaltyEventOrderDetails?.status === 'completed' ? 'success' : 'error'}
                                    size="small"
                                    sx={{ ml: 1 }}
                                />
                            </Grid>
                        </Grid>
                    </Box>) : 
                    <div style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                    <OrderDetailLandingPage selectedOrder={selectedRowData?.orderId}/>
                </div>                
                    }
                </>
            )}
        </Box>
    );
}

export default OrderDetail;
