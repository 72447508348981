import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Checkbox } from '@mui/material';
import { useMutation,useQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { eventServiceClient } from "graphql/client";
import { CREATE_SPONSER } from "services/events-service";
import { GET_EVENTS } from "services/events-service";
import { BRANDS_LIST } from "services/brand-service";


const EventSponser = ({ eventData }) => {
  const [rows, setRows] = useState(eventData?.sponser || []);
  const [selectionModel, setSelectionModel] = useState([]);

  const { loading, data, error } = useQuery(BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      take:1000,
      skip:0,
      filter:{}
    },
  });
  const columns = [
    { 
        field: 'brandId', 
        headerName: 'Brand Name', 
        width: 150,
        renderCell: (params) => {
          const brandId = params?.value;
          const brand = data?.brandlist?.results?.find(brand => brand.id === brandId);
          return brand?.name;
        }
      }, 
      { field: 'sponserTier', headerName: 'Tier Type', width: 150, renderCell: (params) => (<span>{params?.value?.charAt(0)?.toUpperCase() + params?.value?.slice(1)}</span>) },
      { field: 'spocName', headerName: 'SPOC Name', width: 150 },
      { field: 'spocContact', headerName: 'SPOC Contact', width: 150 },
      { field: 'operationName', headerName: 'Operations Person Name', width: 200 },
      { field: 'operationContact', headerName: 'Operations Contact', width: 180 },

    {
      field: 'isApproved',
      headerName: 'Approved',
      width: 120,
      renderCell: (params) => (
        <Checkbox
          checked={params.value}
          onChange={(event) => handleOnCheckboxChange(event, params.row)}
        />
      ),
    },
  ];

  const [createEventSponser] = useMutation(CREATE_SPONSER, {
    client: eventServiceClient,
    refetchQueries: [
      {
        query: GET_EVENTS,
        variables: {
          eventListFilter: { take: 20, skip: 0, search: "", filter: {} },
        },
      },
    ],
  });

  const handleOnCheckboxChange = (event, row) => {
    const { checked } = event.target;

    // Update the row with the new checked value
    const updatedRows = rows.map(item => {
      if (item.id === row.id) {
        return { ...item, isApproved: checked };
      }
      return item;
    });

    // Update the state with the new rows
    setRows(updatedRows);

    const payload = {
      sponserTier: row?.sponserTier,
      eventId: eventData?.id,
      brandId: row?.brandId,
      isApproved: checked
    };

    createEventSponser({ variables: { createEventSponserInput: payload } })
      .then((res) => {
        console.log(res.data["createEventSponser"]);
      })
      .catch((error) => {
        console.error("Error:", error.message);
      });
  };

  const handleSelectionChange = (newSelection) => {
    setSelectionModel(newSelection.selectionModel);
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        pageSizeOptions={[10, 20, 50, 100]}
        selectionModel={selectionModel}
        onSelectionModelChange={handleSelectionChange}
      />
    </div>
  );
};

export default EventSponser;
