import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import { Grid, Pagination as MuiPagination, Box, TablePagination, IconButton, Icon } from '@mui/material';
import dayjs from 'dayjs';
import {  LIST_ALL_FIXTURES, REMOVE_FIXTURE, UPDATE_FIXTURE } from '../../services/advertisment-service';
import { vmServiceClient } from 'graphql/client';
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext, useEffect } from 'react';
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { DropDownSelectionContext, useGridContext } from ".";
import { GET_STORES_LIST_NEW } from "services/store-service";
import { dashboardServiceClient } from "graphql/client";
import { getFlatIdMap } from "../device-Master/utils";
import AgGrid from "components/MDGrid";
import { createSortByFromOrderModel } from "layouts/cutsomer/utils";

const columns = (handleDeleteClick, storeIdMap) =>  [
    {
        field: "code",
        headerName: "Fixture Code",
        width: 250,
        // headerAlign: 'center',
    },
    {
        field: "fixtureType",
        headerName: "Fixture Type",
        width: 250,
    },
    // {
    //     field: "code",
    //     headerName: "Fixture Code",
    //     width: 180,
    // },
    {
        field: "fixtureSize",
        headerName: "Fixture Size",
        width: 250,
    },
    {
        field: "storeId",
        headerName: "Store Name",
        width: 250,
        valueGetter: (params) => storeIdMap[params?.row?.storeId]?.name,
        // headerAlign: 'center',
    },
    {
        field: "location",
        headerName: "Location",
        width: 230,
        valueGetter: (params) => storeIdMap[params?.row?.locationId]?.locationName,
        
    },
    {
        field: "subLocation",
        headerName: "Sub-Location",
        width: 230,
        valueGetter: (params) => storeIdMap[params?.row?.subLocationId]?.name,
    },
    {
        field: "type",
        headerName: "Type",
        width: 230,
        valueGetter: (params) => storeIdMap[params?.row?.subLocationTypeId]?.name,
    },
    {
        field: "isShared",
        headerName: "Shared",
        width: 200,
        valueGetter: (params) => params?.row?.isShared === true ? "Yes" : "No",
    },
    // {
    //     field: "status",
    //     headerName: "Status",
    //     width: 260,
    //     renderCell: (params) => {
    //         // return <Chip label={params.row.isActive === true ? "Active" : "Inactive"} color={params.row.isActive === true ? "success" : "error"} size="small" />;
    //         return (
    //             <select
    //               onChange={(e) =>{
    //                 e.stopPropagation(); //
    //                 handleStatusChange(params.row, e.target.value)
    //               }
    //               }
    //               defaultValue={params.value}
    //               style={{ width: "100%", padding: "10px", borderRadius: "5px" }}
    //             >
    //               <option value="ACTIVE">ACTIVE</option>
    //               <option value="INACTIVE">INACTIVE</option>
    //               <option value="WORKING">WORKING</option>
    //               <option value="NOT_WORKING">NOT_WORKING</option>
    //               <option value="ERROR">ERROR</option>
    //             </select>
    //           );
    //     },
    // },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 140,
        sortable: false,
        renderCell: (params) => (
            <IconButton aria-label="delete" >
                <Icon component={deleteIcon} onClick={(e) => handleDeleteClick(e , params.row.uuid)} fontSize="small" />
            </IconButton>
        )
    },
];

const columnDefs = (handleDeleteClick, storeIdMap) => [
    {
      field: "code",
      headerName: "Fixture Code",
      width: 250,
      headerAlign: 'center',
    },
    {
      field: "fixtureName",
      headerName: "Fixture name",
      width: 250,
      headerAlign: 'center',
    },
    {
      field: "fixtureType",
      headerName: "Fixture Type",
      width: 250,
      filter: 'agSetColumnFilter',
      filterParams: {
          values: ['WALL', 'FLOOR','CROSSWALL','BROWSER','TABLE', 'PILAR BROAD','OTHER'],
      },
      getFilterObj:(filter)=>{
        // console.log(value,"VALUE");
        return {fixtureType:filter.values}
      },
    },
    {
      field: "fixtureSize",
      headerName: "Fixture Size",
      width: 250,
    },
    {
      field: "storeId",
      headerName: "Store Name",
      width: 250,
      valueGetter: (params) => storeIdMap[params.data.storeId]?.name,
      filter:false
    },
    {
      field: "location",
      headerName: "Location",
      width: 230,
      valueGetter: (params) => storeIdMap[params.data.locationId]?.locationName,
      filter:false
    },
    {
      field: "subLocation",
      headerName: "Sub-Location",
      width: 230,
      valueGetter: (params) => storeIdMap[params.data.subLocationId]?.name,
      filter:false
    },
    {
      field: "opsCode",
      headerName: "Ops Fixture Code",
      width: 230
    },
    {
      field: "businessCode",
      headerName: "Business Code",
      width: 230
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 230,
      // filter: 'agDateColumnFilter',
      filter:false,
      getFilterObj:(value)=>({createdAt: value?.dateFrom}),
    },
    {
      field: "updatedAt",
      headerName: "Updated Date",
      width: 230,
      // filter: 'agDateColumnFilter',
      filter:false,
      getFilterObj:(value)=>({updateAt: value?.dateFrom}),
    },
    // {
    //   field: "isShared",
    //   headerName: "Shared",
    //   width: 200,
    //   valueGetter: (params) => params.data.isShared ? "Yes" : "No",
    // },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 140,
      sortable: false,
      filter: false,
      cellRenderer: (params) => (
        <IconButton aria-label="delete">
          <Icon component={deleteIcon} onClick={(e) => handleDeleteClick(e, params.data.uuid)} fontSize="small" />
        </IconButton>
      ),
    }
  ];
function FixtureLandingPage({ onRowClick }) {
    const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
    const { selectedStore, selectedLocation, selectedSubLocation, selectedAssetTypeBatchCode, selectedAssetTypeCode} = useContext(DropDownSelectionContext);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const { externalGridRef } = useGridContext()

    const {
        loading: storeLoading,
        error: storeError,
        data: storeList,
    } = useQuery(GET_STORES_LIST_NEW, {
        client: dashboardServiceClient,
        variables: { input:{take: 100, skip: 0} },
    });

    const [fetchFixtures] = useLazyQuery(LIST_ALL_FIXTURES, {
        client: vmServiceClient,
    });

    const storeIdMap = React.useMemo(()=>getFlatIdMap(storeList?.stores?.results?.filter(store=>!store.isDeleted) || []),[storeList])

    // const { loading, error, data, refetch } = useQuery(LIST_ALL_FIXTURES, {
    //     client: vmServiceClient,
    //     variables: {
    //         page: paginationModel.page + 1,
    //         limit: paginationModel.pageSize,
    //         sortBy: "updatedAt",
    //         order: "DESC",
    //         "searchCols":[],
    //         "searchBy":searchKeyword ? [searchKeyword]: [],
    //         "filter":{
    //             "storeId": selectedStore?.id,
    //             "locationId": selectedLocation?.id,
    //             "subLocationId": selectedSubLocation?.id,
    //             "subLocationTypeId": selectedAssetTypeBatchCode,
    //             "assetTypeCode": selectedAssetTypeCode,
    //         }
    //     },
    //     fetchPolicy: "network-only",
    //     notifyOnNetworkStatusChange: true,
    //     onCompleted: (data) => {
    //         setRowCount(data?.findAllFixtures?.total);
    //     },
    // });
    const [removeFixture, { loading: removing, error: removeError }] =
    useMutation(REMOVE_FIXTURE, {
        client: vmServiceClient,
        refetchQueries: [
            {
                query: LIST_ALL_FIXTURES,
                variables: {
                    page: paginationModel.page + 1,
                    limit: paginationModel.pageSize,
                    "sortBy": "updateAt",
                    "order": "DESC",
                },
                fetchPolicy: "network-only",
            },
        ],
        onCompleted: () => {
            // refetch(); // Ensure refetch is triggered after mutation
            if (externalGridRef) {
              externalGridRef.current?.refreshServerSide({purge:true});
            }
          }
    });
    const handleDeleteClick = async (e, fixtureId) => {
        e.stopPropagation();
        e.preventDefault();
        setIsLoading(true);
        try {
            await removeFixture({
                variables: { id: fixtureId },
            });
            await refetch();
        } catch (error) {
            console.error("Failed to remove fixture:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // const handleStatusChange = async (fixture, statusValue) => {
    //     setIsLoading(true);
    //     try {
    //         await updateFixture({
    //             variables: {
    //                 input: {
    //                     uuid:fixture.uuid,
    //                     storeId:fixture.storeId,
    //                     locationId:fixture.locationId,
    //                     subLocationId:fixture.subLocationId,
    //                     subLocationTypeId:fixture.subLocationTypeId,
    //                     status: statusValue
    //                 }},
    //         });
    //         await refetch();
    //     } catch (error) {
    //         console.error("Failed to update fixture:", error);
    //     } finally {
    //         setIsLoading(false);
    //     }
    // }   
    // const [rowCount, setRowCount] = React.useState(data?.findAllFixtures?.total || 0);

    // React.useEffect(() => {
    //     setRowCount((prevRowCount) =>
    //         data?.findAllFixtures?.total !== undefined
    //             ? data?.findAllFixtures?.total
    //             : prevRowCount,
    //     );
    // }, [data?.findAllFixtures?.total, setRowCount]);
   
    
    // const allFixtures = data?.findAllFixtures?.results;
    
    // if (loading || removing || isLoading)
    //     return (
    //         <>
    //             <Loader />
    //         </>
    //     );
    // if (error || removeError) return <p>Error: {error.message}</p>;

    // const handleChangePage = (event, newPage) => {
    //     setPage(newPage);
    // };

    return (
      // <DataGrid
      //     autoHeight
      //     rows={allFixtures||[]}
      //     columns={columns(handleDeleteClick, storeIdMap)}
      //     rowCount={rowCount}
      //     pageSizeOptions={[10, 20, 50, 100]}
      //     getRowId={(row) => row.uuid}
      //     onRowClick={(params, event)=>{
      //         if (event.target.tagName === 'SELECT' || event.target.closest('select')) {
      //             // Clicked on select dropdown, do nothing or handle dropdown actions
      //             return;
      //           }
      //         onRowClick(params)
      //     }}
      //     loading={loading || isLoading}
      //     onPageChange={handleChangePage}
      //     paginationModel={paginationModel}
      //     paginationMode="server"
      //     onPaginationModelChange={setPaginationModel}
      // />
      <AgGrid
        columns={columnDefs(handleDeleteClick, storeIdMap)}
        allowSearch={true}
        readMethod={{
          fetchFn: fetchFixtures,
          inputParser: ({ startRow, endRow, filter, sortModel, searchCols, searchBy }) => {
            const sortObj = createSortByFromOrderModel(sortModel, columnDefs(handleDeleteClick, storeIdMap));
            // const searchCols = Object.keys(filter) || [];
            // const searchBy = searchCols.map((searchCol) => filter[searchCol]);
            return {
              limit: endRow - startRow,
              page: Math.floor(startRow / (endRow - startRow)) + 1,
              searchCols,
              searchBy,
              filter: {
                ...filter,
                storeId: selectedStore?.id,
                locationId: selectedLocation?.id,
                subLocationId: selectedSubLocation?.id,
                subLocationTypeId: selectedAssetTypeBatchCode,
                assetTypeCode: selectedAssetTypeCode,
              },
              sortBy: sortObj ? sortObj[0] : "updatedAt",
              order: sortObj ? sortObj[1] : "desc",
            };
          },
          resultsParser: ({ data }) => ({
            results: data?.findAllFixtures?.results,
            total: data?.findAllFixtures?.total,
          }),
        }}
        externalGridRef={externalGridRef}
        gridProps={{
          onRowClicked: (row) => {
            if (row.event.defaultPrevented) {
              return null;
            }
            onRowClick({ row: row?.data });
          },
        }}
        defaultColDefProp={{
          filter: "agTextColumnFilter",
          floatingFilter: true,
          floatingFilterComponentParams: { suppressFilterButton: true },
        }}
      />
    );
}

export default FixtureLandingPage;
